@import url('./vars.less');

html,
body,
div,
p,
ul,
ol,
li,
dl,
dd,
dt,
table,
thead,
tbody,
tr,
th,
td,
h1,
h2,
h3,
h4,
h5,
h6 {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  border: none;
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  font-size: inherit;
  font-family: inherit;
}

table {
  border-collapse: collapse;
}

textarea {
  overflow: auto;
  resize: vertical;
}

input,
select,
textarea {
  outline: none;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  appearance: none;
}

html,
body {
  height: 100%;
}

body {
  min-width: @min-width;
  color: @g-text-regular-color;
  background-color: @g-bg-body;
  overflow: auto;
  font: 12px/1.5 'Helvetica Neue', 'Microsoft YaHei', Arial, sans-serif;
}

/* float */
.fl {
  float: left;
}

.fr {
  float: right;
}

.clearfix {
  zoom: 1;
}

.clearfix::after {
  clear: both;
  display: block;
  content: '';
}

/* 自动换行(长英文单词、URL地址、长串数字) */
.word-wrap {
  word-wrap: break-word;
  word-break: break-all;
}

/* 单行溢出省略号 */
.single-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* svg图标样式 */
.zp-icon {
  width: 1em;
  height: 1em;
  overflow: hidden;
  vertical-align: -0.15em;
  fill: currentcolor;
}

.theme-color {
  color: @g-theme-color;
}

.success-color {
  color: @g-success-color;
}

.warning-color {
  color: @g-warning-color;
}

.danger-color {
  color: @g-danger-color;
}

.light-color {
  color: @g-font-light-color;
}

.mt10 {
  margin-top: 10px;
}

.mt20 {
  margin-top: 20px;
}

/* layout css  */
#app {
  height: 100%;
}

.layout {
  height: 100%;
}

.layout-container {
  display: flex;
  height: calc(100% - 56px);
}

/* 如果设置层级z-index时，会遮挡内部的dialog窗口；可给dialog添加append-to-body解决 */
.main-container {
  position: relative;
  flex: 1;
  min-width: 0;
  height: 100%;
}

.page-container {
  height: calc(100% - 48px);
  padding: 20px 20px 0;
  overflow-y: auto;
  .scroll-bar-better();
}

/* search area btns */
.search-container {
  display: flex;
  flex: 1;
  align-items: flex-start;

  .el-form--inline {
    flex: 1;

    .el-form-item {
      margin: 0 20px 16px 0;
    }
  }

  .el-form-item__label {
    width: 80px;
    padding: 0;
    font-size: 13px;
  }

  .el-input,
  .el-select {
    vertical-align: top;
  }

  .el-input,
  .el-date-editor {
    width: 224px;
  }

  .el-range-editor.el-input__inner {
    padding: 3px 8px !important;
  }
}

.search-btn-group {
  display: inline-flex;
  flex-shrink: 0;
  margin-left: 10px;
}

.operation-wrap {
  padding: 12px 24px;
  border-bottom: 1px solid @g-divide-line-color;
}

/* breadcrumb */
.zp-breadcrumb {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 48px;
  padding: 0 20px;
  background-color: @g-white;

  .el-breadcrumb__item {
    .el-breadcrumb__inner {
      &.is-link {
        color: @g-font-light-color;
        font-weight: @g-font-weight-normal;

        &:hover {
          color: @g-theme-color;
        }
      }
    }

    &:last-child {
      .el-breadcrumb__inner {
        color: @g-text-primary-color;
        font-weight: @g-font-weight-normal;
      }
    }
  }

  .el-breadcrumb__separator {
    color: @g-font-light-color;
    font-weight: @g-font-weight-normal;
  }
}

/*** dialog ***/
.zp-dialog {
  .el-dialog {
    width: 480px;
  }

  .el-dialog__header {
    padding: 16px 24px;
    border-bottom: 1px solid @g-divide-line-color;
  }

  .el-dialog__headerbtn {
    top: 20px;
    right: 26px;
  }

  .el-icon-close {
    font-weight: @g-font-weight-bold;
  }

  .el-dialog__title {
    color: @g-text-primary-color;
    font-size: 16px;
    line-height: 24px;
  }

  .el-dialog__body {
    padding: 24px 32px 0;
  }

  .el-dialog__footer {
    padding: 6px 32px 32px;
  }
}

/* .zp-tabs */
.zp-tabs {
  padding: 0 20px;
  background-color: @g-white;

  .el-tabs__header {
    margin-bottom: 0;
  }

  .el-tabs__item {
    height: 56px;
    font-weight: @g-font-weight-normal;
    font-size: 16px;
    line-height: 56px;

    &.is-active {
      font-weight: @g-font-weight-bold;
    }
  }

  .el-badge__content.is-fixed {
    top: 12px;
  }
}

/* message tips */
.zp-message-error {
  z-index: 9999 !important;
}

/*** message box ***/
.el-message-box {
  .el-message-box__title {
    font-size: 16px;
  }

  .el-message-box__btns {
    padding: 5px 15px;
  }
}

/* el-table */
.el-table {
  .cell {
    line-height: 24px;
  }

  th.el-table__cell {
    padding: 4px 0;
    color: @g-text-regular-color;
    background-color: @g-bg-table-header;
    font-weight: @g-font-weight-normal;
    font-size: 12px;
  }

  td.el-table__cell {
    padding: 9px 0;
    color: @g-text-primary-color;
    font-size: 12px;
  }
}

.btn-table-operate {
  display: inline-flex;

  .el-button--text {
    padding: 5px 2px;
    font-weight: @g-font-weight-normal;
    font-size: 12px;
  }

  .danger-color {
    &:focus,
    &:hover {
      color: @g-danger-color;
    }
  }

  .el-button + .el-button {
    margin-left: 4px;
  }
}

/* element reset style */
.el-form-item--small {
  .el-form-item__label {
    font-size: 13px;
  }
}

.el-date-editor {
  .el-range-input,
  .el-range-separator {
    vertical-align: top;
  }
}

.el-input__count {
  line-height: 1;
}

.el-pagination {
  padding: 10px;
  text-align: right;
}

.el-tooltip__popper {
  max-width: 500px;
}

.el-table__empty-text {
  color: @g-placeholder-color;
  font-size: 12px;
}

/* css3动画 */
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease;
}

.slide-fade-enter {
  opacity: 0;
  transform: translateX(20px);
}

.slide-fade-leave-to {
  opacity: 0;
  transform: translateX(-20px);
}

/* custom scroll bar */
.scroll-bar-better {
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgb(0 0 0 / 20%);
    box-shadow: inset 0 0 5px rgb(0 0 0 / 20%);
  }

  &::-webkit-scrollbar-track {
    border-radius: 0;
    background: rgb(0 0 0 / 10%);
    box-shadow: inset 0 0 5px rgb(0 0 0 / 20%);
  }
}
